import { useQuery } from '@apollo/client'
import {
	Alert,
	Button,
	CircularProgress,
	Grid,
	Snackbar,
	Typography,
} from '@mui/material'
import * as Yup from 'yup'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import PaperLooksContainer from '../../components/common/PaperLooksContainer'
import { WORK_PREP_BY_ID } from '../../graphql/queries/workPreps'
import {
	REQUEST_NEW_SIGNATURES,
	EDIT_WORK_PREP,
} from '../../graphql/mutations/workPreps'
import { useEffect, useState } from 'react'
import {
	LocationState,
	SnackbarData,
	WorkPrepAnswers,
	filesURLObj,
} from '../../types'
import { apolloClient } from '../../index'
import { deepCopyObject, isAuthorized } from '../../utils/helperFunctions'
import UploadFileFieldName from '../../components/common/UploadFileFieldName'
import { useTranslation } from 'react-i18next'
import { pdf } from '@react-pdf/renderer'
import DocumentPDF from '../../components/admin/pdf/DocumentPDF'
import LoadingSpinner from '../../components/common/LoadingSpinner'
import { Form, Formik } from 'formik'
import { ScrollToError } from '../../components/formik/ScrollToError'
import ArrowForward from '@mui/icons-material/ArrowForward'
import NotLoggedIn from '../../components/common/NotLoggedIn'
import ErrorOccurred from '../../components/common/ErrorOccurred'
import EditWorkPrepForm from '../../components/forms/EditWorkPrepForm'
import CustomDivider from '../../components/common/CustomDivider'
import InfoText from '../../components/common/InfoText'
import { useAuth } from '../../hooks/useAuth'

const EditWorkPrep = () => {
	const [workPrep, setWorkPrep] = useState<WorkPrepAnswers | null>(null)
	const [deletedFiles, setDeletedFiles] = useState<string[]>([])
	const location = useLocation()
	const state = location.state as LocationState
	const { userEmail, role, jwt, loadingRole } = useAuth()
	const navigate = useNavigate()
	const { t } = useTranslation()

	const [snackbarData, setSnackbarData] = useState<SnackbarData>({
		message: 'This is a success message!',
		severity: 'success',
		open: false,
	})

	const params = useParams()

	const { loading, data } = useQuery(WORK_PREP_BY_ID, {
		variables: { workPrepId: params.workPrepId, jwt },
	})

	const closeSnackbarHandler = () => {
		const tempSnackbarData = { ...snackbarData }
		tempSnackbarData.open = false
		setSnackbarData(tempSnackbarData)
	}

	const isCompleteRequest = (state && state.isFromCompleteRequest) || false

	const extractFileNameFromUrl: (stringName: string) => string = (
		stringName
	) => {
		return stringName.split('/').reverse()[0]
	}

	useEffect(() => {
		window.scrollTo(0, 0) //Scroll to top
		if (data) {
			const workPrep: WorkPrepAnswers = JSON.parse(data.workPrepById)
			setWorkPrep(deepCopyObject(workPrep))
		}
	}, [data])

	if (loading || loadingRole) return <LoadingSpinner />

	if (!workPrep) return <ErrorOccurred />

	const editWorkPrepValidationSchema = Yup.object({
		createdByCompany: Yup.string().required(t('common.required')),
		createdByPersonName: Yup.string().required(t('common.required')),
		telephoneNumberToCreator: Yup.string().required(t('common.required')),
		emailToCreator: Yup.string()
			.email(t('common.invalidEmailAddress'))
			.required(t('common.required')),
		workSteps: Yup.string().required(t('common.required')),
		relevantMall: Yup.string().required(t('common.required')),
		workArea: Yup.string().required(t('common.required')),
		floor: Yup.string().required(t('common.required')),
		storeIdOrName: Yup.string().required(t('common.required')),
		startDate: Yup.date()
			.typeError(t('common.invalidDate'))
			.required(t('common.required')),
		endDate: Yup.date()
			.typeError(t('common.invalidDate'))
			.required(t('common.required'))
			.min(Yup.ref('startDate'), t('common.beforeStart')),
		startingTime: Yup.date()
			.typeError(t('common.invalidTime'))
			.required(t('common.required')),
		endingTime: Yup.date()
			.typeError(t('common.invalidTime'))
			.required(t('common.required')),
		numPeople: Yup.number()
			.min(1, t('workPrep.validation.atLeastOnePerson'))
			.integer(t('common.mustBeInteger'))
			.typeError(t('common.mustBeInteger'))
			.required(t('common.required')),
		insuranceCompanyName: Yup.string().required(t('common.required')),
		insuranceContractNumber: Yup.string().required(t('common.required')),
		nameToProjectManager: Yup.string().when('projectManagerExists', {
			is: true,
			then: Yup.string().required(t('common.required')),
		}),
		telephoneToProjectManager: Yup.string().when('projectManagerExists', {
			is: true,
			then: Yup.string().required(t('common.required')),
		}),
		emailToProjectManager: Yup.string()
			.email(t('common.invalidEmailAddress'))
			.when('projectManagerExists', {
				is: true,
				then: Yup.string().required(t('common.required')),
			}),
		workWithWaterCertificate: Yup.lazy((val, context) => {
			const notDeletedFiles =
				workPrep.filesURLObj?.workWithWaterCertificate.filter(
					(el) => !deletedFiles.includes(el)
				)
			if (
				context.parent.workWithWaterWillBeDone &&
				!notDeletedFiles?.length
			) {
				return Yup.array().required().min(1, t('common.fileRequired'))
			}
			return Yup.mixed().nullable(true)
		}),
		electricalWorkCertificate: Yup.lazy((val, context) => {
			const notDeletedFiles =
				workPrep.filesURLObj?.electricalWorkCertificate.filter(
					(el) => !deletedFiles.includes(el)
				)
			if (
				context.parent.electricalWorkWillBeDone &&
				!notDeletedFiles?.length
			) {
				return Yup.array().required().min(1, t('common.fileRequired'))
			}
			return Yup.mixed().nullable(true)
		}),
		constructionEnvironmentWorkPlan: Yup.lazy((val, context) => {
			const notDeletedFiles =
				workPrep.filesURLObj?.constructionEnvironmentWorkPlan.filter(
					(el) => !deletedFiles.includes(el)
				)
			if (
				context.parent.constructionWorkWillTakePlace &&
				!notDeletedFiles?.length
			) {
				return Yup.array().required().min(1, t('common.fileRequired'))
			}
			return Yup.mixed().nullable(true)
		}),
		baseUCertificate: Yup.lazy((val, context) => {
			const notDeletedFiles =
				workPrep.filesURLObj?.baseUCertificate.filter(
					(el) => !deletedFiles.includes(el)
				)
			if (
				context.parent.baseUAndBasePExists &&
				!notDeletedFiles?.length
			) {
				return Yup.array().required().min(1, t('common.fileRequired'))
			}
			return Yup.mixed().nullable(true)
		}),
		basePCertificate: Yup.lazy((val, context) => {
			const notDeletedFiles =
				workPrep.filesURLObj?.basePCertificate.filter(
					(el) => !deletedFiles.includes(el)
				)
			if (
				context.parent.baseUAndBasePExists &&
				!notDeletedFiles?.length
			) {
				return Yup.array().required().min(1, t('common.fileRequired'))
			}
			return Yup.mixed().nullable(true)
		}),
		nameToURWBaseU: Yup.string().when('baseUAndBasePExists', {
			is: true,
			then: Yup.string().required(t('common.required')),
		}),
		telephoneToURWBaseU: Yup.string().when('baseUAndBasePExists', {
			is: true,
			then: Yup.string().required(t('common.required')),
		}),
		emailToURWBaseU: Yup.string()
			.email(t('common.invalidEmailAddress'))
			.when('baseUAndBasePExists', {
				is: true,
				then: Yup.string().required(t('common.required')),
			}),
		nameToBaseP: Yup.string().when('baseUAndBasePExists', {
			is: true,
			then: Yup.string().required(t('common.required')),
		}),
		telephoneToBaseP: Yup.string().when('baseUAndBasePExists', {
			is: true,
			then: Yup.string().required(t('common.required')),
		}),
		emailToBaseP: Yup.string()
			.email(t('common.invalidEmailAddress'))
			.when('baseUAndBasePExists', {
				is: true,
				then: Yup.string().required(t('common.required')),
			}),
		willTheWorkTakeLongerThan30DaysCertificate: Yup.lazy((val, context) => {
			const notDeletedFiles =
				workPrep.filesURLObj?.willTheWorkTakeLongerThan30DaysCertificate.filter(
					(el) => !deletedFiles.includes(el)
				)
			if (
				context.parent.willTheWorkTakeLongerThan30Days &&
				!notDeletedFiles?.length
			) {
				return Yup.array().required().min(1, t('common.fileRequired'))
			}
			return Yup.mixed().nullable(true)
		}),
		hotWorkPermission: Yup.boolean(),
		hotWorkPermitManager: Yup.string(),
		clientName: Yup.string(),
		clientTelephone: Yup.string(),
		clientEmail: Yup.string().email(t('common.invalidEmailAddress')),
	})

	const initialFormikValues = {
		workSteps: workPrep.workSteps,
		relevantMall: workPrep.relevantMall,
		createdByCompany: workPrep.creatorOfWorkPrep.company,
		createdByPersonName: workPrep.creatorOfWorkPrep.name,
		telephoneNumberToCreator: workPrep.creatorOfWorkPrep.telephoneNumber,
		emailToCreator: workPrep.creatorOfWorkPrep.email,
		workArea: workPrep.workArea,
		floor: workPrep.floor,
		numPeople: workPrep.numPeople,
		storeIdOrName: workPrep.storeIdOrName,
		startDate: workPrep.startDate,
		endDate: workPrep.endDate,
		startingTime: new Date(
			1,
			1,
			1,
			Number(workPrep.workingHours.startingTime.split(':')[0]),
			Number(workPrep.workingHours.startingTime.split(':')[1])
		),
		endingTime: new Date(
			1,
			1,
			1,
			Number(workPrep.workingHours.endingTime.split(':')[0]),
			Number(workPrep.workingHours.endingTime.split(':')[1])
		),

		projectManagerExists: workPrep.projectManager.exists,
		nameToProjectManager: workPrep.projectManager.name,
		telephoneToProjectManager: workPrep.projectManager.telephoneNumber,
		emailToProjectManager: workPrep.projectManager.email,

		hotWorkPermission: workPrep.hotWorkPermission.answer,
		hotWorkPermitManager: workPrep.hotWorkPermission.hotWorkPermitManager,

		fireAlarmWillBeShutOff: workPrep.fireAlarmWillBeShutOff.answer,
		whenWillFireAlarmBeShutOff: workPrep.fireAlarmWillBeShutOff.timePeriod,

		workWithWaterWillBeDone: workPrep.workWithWaterWillBeDone.answer,
		workWithWaterCertificate: [],

		ventilationWillBeShutOff: workPrep.ventilationWillBeShutOff.answer,
		whenWillVentilationBeShutOff:
			workPrep.ventilationWillBeShutOff.timePeriod,

		electricalWorkWillBeDone: workPrep.electricalWorkWillBeDone.answer,
		electricalWorkCertificate: [],

		sprinklerSystemWillBeShutOff:
			workPrep.sprinklerSystemWillBeShutOff.answer,
		whenWillSprinklerSystemBeShutOff:
			workPrep.sprinklerSystemWillBeShutOff.timePeriod,

		constructionWorkWillTakePlace:
			workPrep.constructionWorkWillTakePlace.answer,
		constructionEnvironmentWorkPlan: [],

		urwBaseUExists: workPrep.constructionWorkWillTakePlace.urwBaseU.exists,
		nameToURWBaseU: workPrep.constructionWorkWillTakePlace.urwBaseU.name,
		telephoneToURWBaseU:
			workPrep.constructionWorkWillTakePlace.urwBaseU.telephoneNumber,
		emailToURWBaseU: workPrep.constructionWorkWillTakePlace.urwBaseU.email,

		baseUCertificate: [],
		basePCertificate: [],
		scheduleForTheProject: [],

		clientName: workPrep.client?.name || '',
		clientTelephone: workPrep.client?.telephone || '',
		clientEmail: workPrep.client?.email || '',

		willTheWorkTakeLongerThan30Days:
			workPrep.willTheWorkTakeLongerThan30Days.answer,
		willTheWorkTakeLongerThan30DaysCertificate: [],

		basePExists: workPrep.constructionWorkWillTakePlace.baseP.exists,
		nameToBaseP: workPrep.constructionWorkWillTakePlace.baseP.name,
		telephoneToBaseP:
			workPrep.constructionWorkWillTakePlace.baseP.telephoneNumber,
		emailToBaseP: workPrep.constructionWorkWillTakePlace.baseP.email,

		baseUAndBasePExists:
			workPrep.constructionWorkWillTakePlace.urwBaseU.exists ||
			workPrep.constructionWorkWillTakePlace.baseP.exists,

		insuranceCompanyName: workPrep.insurance.company.split(':')[0],
		insuranceContractNumber: workPrep.insurance.company.split(':')[1],

		certainRisks: workPrep.certainRisks,

		areaWhereWorkWillBePerformed: workPrep.areaWhereWorkWillBePerformed,

		measureForWorkEnvironmentRisks: workPrep.measureForWorkEnvironmentRisks,

		describeWorkSteps: workPrep.describeWorkSteps,

		additionalResources: workPrep.additionalResources,

		liftCardsForPersonalWorkingWithLifts: [],
	}

	const requestNewSignatures = async (
		editedWorkPrep: WorkPrepAnswers,
		values: any
	) => {
		const tempWorkPrep = { ...editedWorkPrep }
		tempWorkPrep.creatorOfWorkPrep.signStatus = '' //Removes the sign list on PDF

		const workPrepBlob = await pdf(
			<DocumentPDF currentWorkPrep={tempWorkPrep} customLang={'sv'} />
		).toBlob()

		const workPrepPDF = new File([workPrepBlob], 'filename.pdf')

		const isBasUUpdated =
			values.baseUCertificate.length > 0 ||
			workPrep.constructionWorkWillTakePlace.urwBaseU.email !=
				editedWorkPrep.constructionWorkWillTakePlace.urwBaseU.email
		const isBasPUpdated =
			values.basePCertificate.length > 0 ||
			workPrep.constructionWorkWillTakePlace.baseP.email !=
				editedWorkPrep.constructionWorkWillTakePlace.baseP.email
		const isConstructionEnvironmentWorkPlanUpdated =
			values.constructionEnvironmentWorkPlan.length > 0

		const res = await apolloClient.mutate({
			mutation: REQUEST_NEW_SIGNATURES,
			variables: {
				workPrepId: editedWorkPrep.id,
				isBasUUpdated: isBasUUpdated,
				isBasPUpdated: isBasPUpdated,
				isConstructionEnvironmentWorkPlanUpdated:
					isConstructionEnvironmentWorkPlanUpdated,
				workPrepPDF,
				jwt,
				isCompleteRequest: true,
			},
		})

		return res.data.requestNewSignatures
	}

	const handleSubmit = async (values: any, actions: any) => {
		setSnackbarData({
			message: t('workPrepDetails.updating'),
			severity: 'info',
			open: true,
		})

		let startingTimeHours = `${values.startingTime.getHours()}`,
			startingTimeMinutes = `${values.startingTime.getMinutes()}`,
			endingTimeHours = `${values.endingTime.getHours()}`,
			endingTimeMinutes = `${values.endingTime.getMinutes()}`

		if (parseInt(startingTimeHours) < 10) {
			startingTimeHours = `0${startingTimeHours}`
		}

		if (parseInt(startingTimeMinutes) < 10) {
			startingTimeMinutes = `0${startingTimeMinutes}`
		}

		if (parseInt(endingTimeHours) < 10) {
			endingTimeHours = `0${endingTimeHours}`
		}

		if (parseInt(endingTimeMinutes) < 10) {
			endingTimeMinutes = `0${endingTimeMinutes}`
		}

		console.log('DONE: Parsing times')
		console.log(workPrep)

		const editedWorkPrep: WorkPrepAnswers = {
			id: workPrep.id,
			status: workPrep.status,
			statusChangedBy: workPrep.statusChangedBy,
			statusChangedOn: workPrep.statusChangedOn,
			workSteps: values.workSteps,
			relevantMall: values.relevantMall,
			creatorOfWorkPrep: {
				caseId: workPrep.creatorOfWorkPrep.caseId,
				company: values.createdByCompany,
				name: values.createdByPersonName,
				telephoneNumber: values.telephoneNumberToCreator,
				email: values.emailToCreator,
				signStatus: workPrep.creatorOfWorkPrep.signStatus,
				signStatusChangedOn:
					workPrep.creatorOfWorkPrep.signStatusChangedOn,
				signatureType: workPrep.creatorOfWorkPrep.signatureType,
			},
			establishedDate: workPrep.establishedDate,
			workArea: values.workArea,
			floor: values.floor,
			storeIdOrName: values.storeIdOrName,
			startDate: values.startDate!,
			endDate: values.endDate!,
			workingHours: {
				startingTime: `${startingTimeHours}:${startingTimeMinutes}`,
				endingTime: `${endingTimeHours}:${endingTimeMinutes}`,
			},
			numPeople: values.numPeople.toString(),
			client: {
				name: values.clientName,
				telephone: values.clientTelephone,
				email: values.clientEmail,
			},
			projectManager: {
				caseId: workPrep.projectManager.caseId,
				exists: values.projectManagerExists,
				name: values.nameToProjectManager,
				telephoneNumber: values.telephoneToProjectManager,
				email: values.emailToProjectManager,
				signStatus: workPrep.projectManager.signStatus,
				signStatusChangedOn:
					workPrep.projectManager.signStatusChangedOn,
				signatureType: workPrep.projectManager.signatureType,
			},
			hotWorkPermission: {
				answer: values.hotWorkPermission,
				hotWorkPermitManager:
					workPrep.hotWorkPermission.hotWorkPermitManager,
			},
			fireAlarmWillBeShutOff: {
				answer: values.fireAlarmWillBeShutOff,
				timePeriod: values.whenWillFireAlarmBeShutOff,
			},
			workWithWaterWillBeDone: {
				answer: values.workWithWaterWillBeDone,
			},
			ventilationWillBeShutOff: {
				answer: values.ventilationWillBeShutOff,
				timePeriod: values.whenWillVentilationBeShutOff,
			},
			electricalWorkWillBeDone: {
				answer: values.electricalWorkWillBeDone,
			},

			sprinklerSystemWillBeShutOff: {
				answer: values.sprinklerSystemWillBeShutOff,
				timePeriod: values.whenWillSprinklerSystemBeShutOff,
			},

			constructionWorkWillTakePlace: {
				answer: values.constructionWorkWillTakePlace,

				urwBaseU: {
					caseId: workPrep.constructionWorkWillTakePlace.urwBaseU
						.caseId,
					exists: values.baseUAndBasePExists,
					name: values.nameToURWBaseU,
					telephoneNumber: values.telephoneToURWBaseU,
					email: values.emailToURWBaseU,
					signStatus:
						workPrep.constructionWorkWillTakePlace.urwBaseU
							.signStatus,
					signStatusChangedOn:
						workPrep.constructionWorkWillTakePlace.urwBaseU
							.signStatusChangedOn,
					signatureType:
						workPrep.constructionWorkWillTakePlace.urwBaseU
							.signatureType,
				},
				baseP: {
					caseId: workPrep.constructionWorkWillTakePlace.baseP.caseId,
					exists: values.baseUAndBasePExists,
					name: values.nameToBaseP,
					telephoneNumber: values.telephoneToBaseP,
					email: values.emailToBaseP,
					signStatus:
						workPrep.constructionWorkWillTakePlace.baseP.signStatus,
					signStatusChangedOn:
						workPrep.constructionWorkWillTakePlace.baseP
							.signStatusChangedOn,
					signatureType:
						workPrep.constructionWorkWillTakePlace.baseP
							.signatureType,
				},
			},
			willTheWorkTakeLongerThan30Days: {
				answer: values.willTheWorkTakeLongerThan30Days,
			},
			insurance: {
				exists: workPrep.insurance.exists,
				company: `${values.insuranceCompanyName}:${values.insuranceContractNumber}`,
			},

			certainRisks: values.certainRisks,

			measureForWorkEnvironmentRisks:
				values.measureForWorkEnvironmentRisks,

			areaWhereWorkWillBePerformed: values.areaWhereWorkWillBePerformed,

			describeWorkSteps: values.describeWorkSteps,

			additionalResources: values.additionalResources,
			lastEditedOn: new Date().toString(),
			lastEditedBy: isCompleteRequest
				? workPrep.creatorOfWorkPrep.email
				: userEmail,
			isCreatedLoggedIn: workPrep.isCreatedLoggedIn,
		}

		console.log('DONE: Compiling workprep answers')
		console.log(editedWorkPrep)

		const workPrepBlob = await pdf(
			<DocumentPDF currentWorkPrep={editedWorkPrep} customLang="sv" />
		).toBlob()

		const workPrepPDF = new File([workPrepBlob], 'filename.pdf')

		console.log('DONE: Creating PDF')
		console.log(workPrepPDF)

		await apolloClient.mutate({
			mutation: EDIT_WORK_PREP,
			variables: {
				workPrep: JSON.stringify(editedWorkPrep),
				workPrepPDF,
				workWithWaterCertificate: values.workWithWaterCertificate,
				electricalWorkCertificate: values.electricalWorkCertificate,
				constructionEnvironmentWorkPlan:
					values.constructionEnvironmentWorkPlan,
				baseUCertificate: values.baseUCertificate,
				basePCertificate: values.basePCertificate,
				willTheWorkTakeLongerThan30DaysCertificate:
					values.willTheWorkTakeLongerThan30DaysCertificate,
				scheduleForTheProject: values.scheduleForTheProject,
				liftCardsForPersonalWorkingWithLifts:
					values.liftCardsForPersonalWorkingWithLifts,
				deletedFiles,
				jwt,
			},
		})

		console.log('DONE: Calling apollo')

		const isBasUUpdated =
			values.baseUCertificate.length > 0 ||
			workPrep.constructionWorkWillTakePlace.urwBaseU.email !=
				editedWorkPrep.constructionWorkWillTakePlace.urwBaseU.email
		const isBasPUpdated =
			values.basePCertificate.length > 0 ||
			workPrep.constructionWorkWillTakePlace.baseP.email !=
				editedWorkPrep.constructionWorkWillTakePlace.baseP.email
		const isConstructionEnvironmentWorkPlanUpdated =
			values.constructionEnvironmentWorkPlan.length > 0

		const somethingImportantIsUpdated =
			isBasUUpdated ||
			isBasPUpdated ||
			isConstructionEnvironmentWorkPlanUpdated
		const containsStatus = editedWorkPrep.status.length > 0
		const shouldRequestNewSignatures =
			isCompleteRequest &&
			(!containsStatus || somethingImportantIsUpdated)

		//Only send for new signatures if made as complete request. Admins has this function as a button in admin panel
		//If workprep is already handeled, new signatures only is needed if basU/basP/CEWP is updated.
		if (shouldRequestNewSignatures) {
			console.log('Requesting new signatures')
			const res = await requestNewSignatures(editedWorkPrep, values)
			if (res === 'Assently error') {
				setSnackbarData({
					message: t('workPrep.assentlyError'),
					severity: 'error',
					open: true,
				})
			} else {
				setSnackbarData({
					message: t('editWorkPrep.completeRequest.sent'),
					severity: 'success',
					open: true,
				})
			}
			try {
				const redirectUrl = new URL(res)
				window.location.replace(redirectUrl)
			} catch {
				setSnackbarData({
					message: t('workPrep.urlError'),
					severity: 'error',
					open: true,
				})
			}
		} else {
			if (isCompleteRequest) {
				navigate('/')
			} else {
				navigate(`/admin-panel/${editedWorkPrep.id}`)
			}
		}
	}

	//Renders all the already uploaded files for a type
	const getUploadedFilesFromType = (type: keyof filesURLObj) => {
		const filesArray = []
		if (workPrep.filesURLObj) {
			for (const key in workPrep.filesURLObj[type]) {
				const url = workPrep.filesURLObj[type][key]
				const fileName = extractFileNameFromUrl(url)
				if (!deletedFiles.includes(url)) {
					filesArray.push(
						<UploadFileFieldName
							key={fileName}
							name={fileName}
							handleDelete={() =>
								setDeletedFiles([...deletedFiles, url])
							}
						/>
					)
				}
			}
			if (filesArray.length) {
				filesArray.unshift(
					<Typography key={'1'} fontSize={14} marginBottom={'10px'}>
						{t('editWorkPrep.uploadedFiles')}
					</Typography>
				)
			}
		}
		return filesArray
	}
	const isOwnWorkPrep =
		userEmail === workPrep?.isCreatedLoggedIn?.email ||
		userEmail === workPrep?.projectManager?.email
	if (
		!isOwnWorkPrep &&
		!isCompleteRequest &&
		!isAuthorized(role, workPrep.relevantMall)
	) {
		return <NotLoggedIn />
	}

	return (
		<>
			<PaperLooksContainer
				isCompleteRequest={isCompleteRequest}
				backBtnPath={`/admin-panel/${params.workPrepId}`}
			>
				<Grid
					container
					paddingX={{ xs: 0, sm: 5 }}
					paddingY={5}
					direction={'column'}
					rowSpacing={2}
				>
					<Grid item>
						<h1>{t('workPrep.title') + ': ' + workPrep.id}</h1>
					</Grid>
					<CustomDivider />
					<Grid item>
						<Formik
							initialValues={initialFormikValues}
							onSubmit={handleSubmit}
							validationSchema={editWorkPrepValidationSchema}
						>
							{({ isSubmitting }) => (
								<Form id={'1'}>
									<ScrollToError />
									<EditWorkPrepForm
										setSnackbarData={setSnackbarData}
										getUploadedFiles={
											getUploadedFilesFromType
										}
										isCompleteRequest={isCompleteRequest}
									/>
									<Grid item>
										<InfoText
											sx={{
												borderColor: '#ef4444',
												backgroundColor: '#fef2f2',
												marginBottom: '10px',
											}}
										>
											<Typography>
												{t('editWorkPrep.warning')}
											</Typography>
										</InfoText>
									</Grid>
									<Grid
										item
										display={'flex'}
										justifyContent={'right'}
									>
										<Button
											disabled={isSubmitting}
											type="submit"
											variant="contained"
											endIcon={
												isSubmitting ? (
													<CircularProgress
														size={20}
													/>
												) : (
													<ArrowForward />
												)
											}
											sx={{ height: 50 }}
										>
											{isCompleteRequest
												? t('workPrep.sign')
												: t('workPrep.save')}
										</Button>
									</Grid>
								</Form>
							)}
						</Formik>
					</Grid>
				</Grid>
			</PaperLooksContainer>

			<Snackbar
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				open={snackbarData.open}
				onClose={closeSnackbarHandler}
				key={'bottom center'}
			>
				<Alert
					onClose={closeSnackbarHandler}
					severity={snackbarData.severity}
					sx={{ width: '100%' }}
				>
					{snackbarData.message}
				</Alert>
			</Snackbar>
		</>
	)
}

export default EditWorkPrep
